import WalletList from "../wallet/WalletList";
import Button from 'react-bootstrap/Button';
import { useState } from "react";
import AddModal from "../wallet/AddModal";

const WalletPage = (props) => {
  const [wallets, setWallets] = useState(  
    [
      {
        name: "US Bank",
        id: "111141331313",
        amount: 10,
      },
      {
          name: "Mokeys Are Us",
          id: "3294924949",
          amount: 69
      },
      {
        name: "Test",
        id: "121351643421",
        amount: 0
      }
    ]
  )
  const [selected, setSelected] = useState()

  const [add, setAdd] = useState(false)

  const handleClose = () => {
    setAdd(false)
  }

  const handleSelect = (id) => {
    setSelected(id)
  }

  const handleAddClick = () => {
    console.log("Handling add click")
    setAdd(true)
  }

  const onNewWallet = (wallet) => {
    console.log(wallet)
    console.log("Adding a new record")
    const newWallets = wallets.slice()
    newWallets.push(wallet)
    setWallets(newWallets)
  }

  const handleRemoveClick = () => {
    console.log("Hanlding Remove Click")
    const newWallets = wallets.slice().filter((x) => x.id !== selected)
    setWallets(newWallets)
  }

  const handleDirectRemoval = (id) => {
    console.log("Hanlding Direct Removal")
    const newWallets = wallets.slice().filter((x) => x.id !== id)
    setWallets(newWallets)
  }
  return (
    <div className="walletPage">
      <WalletList wallets={wallets} selected={selected} onSelect={handleSelect} onRemove={handleDirectRemoval}></WalletList>
      <Button variant="primary" onClick={handleAddClick}>Add</Button>{' '}
      <Button variant="danger" onClick={handleRemoveClick}>Remove</Button>
      <AddModal show={add} onClose={handleClose} onAdd={onNewWallet}/>
    </div>
  );
};

export default WalletPage;
