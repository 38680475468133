import React from 'react';
import './App.css';
import WalletPage from './components/pages/WalletPage';
import { Navbar } from 'react-bootstrap';

const App = ()=>{
  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">Nexio Wallet</Navbar.Brand>
      </Navbar>
      <WalletPage/>
    </React.Fragment>
  );
}


export default App;
