import React from "react";
import WalletInfo from "./WalletInfo";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const WalletList = (props) => {
  const wallets = Array.isArray(props.wallets) ? props.wallets : [];

  return (
    <React.Fragment>
      <Card style={{ width: "18rem" }}>
        <ListGroup variant="flush">
          {wallets.map((item) => {
            return (
              <ListGroup.Item
                onClick={() => {
                  props.onSelect(item.id);
                }}
                active={props.selected === item.id}
                key={item.id}
              >
                <WalletInfo wallet={item} />{" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    props.onRemove(item.id);
                  }}
                >
                  <Icon.XCircle />
                </Button>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Card>
    </React.Fragment>
  );
};

export default WalletList;
