import React from "react";

const WalletInfo = (props) => {
  const name = props.wallet.name;
  const id = props.wallet.id;
  const amount = props.wallet.amount;

  return (
    <React.Fragment>
      <h1>{name}</h1>
      <h5>{id}</h5>
      <h6>${amount}</h6>
    </React.Fragment>
  );
};

export default WalletInfo;
