import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';

const AddModal = (props) => {

    const [bankName, setBankName] = useState('');
    const [amount, setAmount] = useState(0);

    const handleBankNameChange = (e) => {
        setBankName(e.target.value)
    }
    const handleAmountChange = (e) => {
        setAmount(e.target.value)
    }
    const createWallet = () => {
        const newWallet = {
            name: bankName,
            id: uuidv4(),
            amount,
        }
        props.onAdd(newWallet)
        props.onClose()
        setBankName('')
        setAmount(0)
    }
    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Bank</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="formBankName">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" value={bankName} placeholder="Enter Bank Name" onChange={handleBankNameChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="text" value={amount} placeholder="Enter Bank Amount" onChange={handleAmountChange}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant="success" onClick={createWallet}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddModal
